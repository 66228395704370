import React from 'react'
import Card from './Card'
import Footer from './Footer'
import UpperNav from './UpperNav'
import pool from "../assets/pool.jpeg"
import pool1 from "../assets/pool1.jpeg"
import pool2 from "../assets/pool2.jpeg"



function Pool() {
  return (
    <div>
      <div>
        <Card img={pool} name="SWIMMING POOL" desc1="As part of our commitment to providing exceptional amenities to our guests, we are proud to introduce our state-of-the-art swimming pool facility. Dive into crystal-clear waters and soak up the sunshine as you lounge poolside. Our swimming pool is meticulously maintained and provides a refreshing escape for guests of all ages. Take a leisurely swim to unwind after a busy day of exploring the city, or start your morning with a few laps to invigorate your senses." 
        desc2="For those looking to elevate their poolside experience, we offer poolside service, ensuring you stay refreshed with a selection of beverages and snacks while you soak up the sun. Whether you're traveling for business or leisure, our swimming pool facility provides the perfect opportunity to rejuvenate and recharge during your stay at Hotel Girish. Make a splash and make memories that will last a lifetime at our luxurious oasis."/>
      </div>
      <div className='sm:grid grid-cols-2 mx-10 my-10 sm:space-x-5 space-y-3 sm:space-y-0'>
        <div><img src={pool1} alt="" className='rounded-lg'/></div>
        <div><img src={pool2} alt="" className='rounded-lg'/></div>
      </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default Pool
