import React from "react";
import { Link } from "react-router-dom";
import "primeicons/primeicons.css";
import sofa from "../assets/sofa.svg";
import ac from "../assets/air-conditioner.svg";
import wardrobe from "../assets/wardrobe.svg";
import shampoo from "../assets/shampoo.svg";
import {
  FaCoffee,
  FaPhone,
  FaSoap,
  FaTv,
  FaWifi,
} from "react-icons/fa";

function Rooms({
  img,
  name,
  desc,
  seeMore,
}) {
  const handleToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="bg-gray-200 p-3 rounded-lg">
        <div>
          <img src={img} alt="" className="rounded-lg" />
        </div>
        <div className="my-4">
          <div className="font-semibold text-xl text-center">{name}</div>
          <div className="underline mt-8">Room Amenities:</div>
          <div className="text-3xl grid grid-cols-4 sm:grid-cols-6 space-x-3 space-y-2 justify-center items-center mx-auto my-4">
            <div className="">
              <label title="Free Wifi" htmlFor="">
                <FaWifi className="my-2 mx-4" />
              </label>
            </div>
            <div className="">
              <label title="LED TV - CABLE TV" htmlFor="">
                <FaTv />
              </label>
            </div>
            <div className="">
              <label title="Telephone" htmlFor="">
                <FaPhone className="text-2xl" />
              </label>
            </div>
            <div className="">
              <label title="Coffee and Tea Facilities" htmlFor="">
                <FaCoffee />
              </label>
            </div>
            <div className="">
              <label title="Air Conditioner" htmlFor="">
                <img src={ac} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className="">
              <label title="Sofa" htmlFor="">
                <img src={sofa} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className="">
              <label title="Wardrobe" htmlFor="">
                <img src={wardrobe} alt="" className="h-8 w-9" />
              </label>
            </div>
          </div>
          <div className="underline mt-5">Bathroom Amenities:</div>
          <div className="text-4xl grid grid-cols-4 sm:grid-cols-6 space-x-3 space-y-2 justify-center items-center mx-auto my-4">
            <div className="">
              <label title="Soap" htmlFor="">
                <FaSoap className="my-2 mx-4" />
              </label>
            </div>
            <div className="">
              <label title="Shampoo and Conditioner" htmlFor="">
                <img src={shampoo} alt="" className="h-9 w-10" />
              </label>
            </div>
          </div>
          <div className="">
            <div>
              {desc}
              <div className={seeMore}>
                ...{" "}
                <Link to="/rooms" className="text-blue-600 underline" onClick={handleToTop}>
                  See more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rooms;