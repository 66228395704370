import React from 'react'

function Card({img, name, desc1, desc2}) {
  return (
    <div>
        <div className='sm:grid grid-cols-2 mx-10 my-20'>
            <div className=''>
            <img src={img} alt="" className='rounded-lg sm:h-96'/>
            </div>
            <div className='flex flex-col justify-between my-3'>
                <div className='text-center'>
                    <div className='font-bold text-2xl underline'>{name}</div>
                    <div className='sm:px-10 py-5'>{desc1}</div>
                    <div className='sm:px-10'>{desc2}</div>
                </div>
                <div>
                    <a href="tel:+919593355995">
                        <div className="mt-8 sm:px-20 flex justify-center items-center">
                        <button className="bg-blue-900 text-white px-3 py-2 w-full rounded-lg font-semibold">
                            Book Now
                        </button>
                        </div>
                    </a>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default Card
