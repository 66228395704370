import React from 'react'
import lawn from "../assets/lawn.jpeg"
import Card from './Card'
import Footer from './Footer'
import UpperNav from './UpperNav'


function Lawn() {
  return (
    <div>
      <div>
        <Card img={lawn} name="LAWN" desc1="At Hotel Girish, we understand the importance of creating unforgettable moments for our guests. That's why we take pride in offering our exquisite lawn facility for your special events and occasions. Imagine exchanging vows surrounded by lush greenery and blooming flowers, or celebrating a milestone birthday under the open sky with your loved ones. Our picturesque lawn provides the perfect backdrop for any event, whether it's a dreamy wedding reception, a corporate gala, or a festive family reunion." desc2="Experience the epitome of elegance and sophistication at Hotel Girish. Contact us today to learn more about our lawn facility and start planning your unforgettable event with us. Your dream celebration awaits amidst the natural beauty of our enchanting surroundings."/>
      </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default Lawn
