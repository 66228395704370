import React from 'react';
import homeImg from "../assets/index.jpeg"
import UpperNav from './UpperNav';
import About from './About';
import Rooms from './Rooms';
import Footer from '../components/Footer'
import CarouselDefault from "../components/CarouselDefault"

import room1 from "../assets/room1.jpeg";
import room2 from "../assets/room2.jpeg";
import room3 from "../assets/room3.jpg";

function Body() {
  const data= [
    {
        img:"i1.jpeg"
    },
    {
        img:"i2.jpg"
    },
    {
        img:"i3.jpeg"
    },
    {
        img:"i4.jpeg"
    },
    {
        img:"i5.jpeg"
    },
    {
        img:"i6.jpeg"
    },
    {
        img:"i7.jpg"
    },
];
  return (
    <div>
      <div className='mx-5 my-5'>
        <img src={homeImg} alt="" className='h-[50vh] sm:h-[750px] w-[83vw] ml-3 sm:ml-20 rounded-lg'/>
      </div>
        <div className='sm:hidden sticky top-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
      <div className='mx-10 my-20'>
        <About/>
      </div>
      <div className='mx-5 my-10'>
        <div className='underline font-bold text-3xl text-blue-900 text-center my-5'>Rooms</div>
        <div className='sm:grid grid-cols-3 sm:space-x-3 space-y-2 sm:space-y-0 my-5'>
            <Rooms img={room1} name={"Deluxe Room"} desc={"Upgrade your stay with spacious and sumptuously appointed beds, carefully curated furnishings, and contemporary design elements adorned with the finest linens, promising a night of sublime relaxation."}/>
            <Rooms img={room2} name={"Executive Room"} desc={"Modern and stylish, these rooms offer comfortable beds with plush bedding, and other amenities ensuring a restful night sleep after a day of exploration or work."}/>
            <Rooms img={room3} name={"Suite Room"} desc={"Welcome to our Suite Room! We're thrilled to welcome you to a world of luxury and comfort. Indulge in the lavish amenities and elegant design of our premium suite accommodations."}/>
        </div>
      </div>
      <div className='mx-5 my-20 text-center'>
            <div className='text-3xl text-blue-900 font-semibold'>Look Around</div>
            <div className='my-10 sm:px-20 sm:pb-20'>
                <CarouselDefault data={data}/>
            </div>
        </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
    </div>
  )
}

export default Body