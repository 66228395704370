import React from 'react'
import Navbar from '../components/Navbar'
import { BrowserRouter,  Route,  Routes } from 'react-router-dom'
import Body from '../components/Body'
import AboutPage from '../components/AboutPage'
import RoomPage from '../components/RoomPage'
import Restaurant from '../components/Restaurant'
import Contact from '../components/Contact'
import Banquet from "../components/Banquet"
import PageNotFound from '../components/PageNotFound'
import DestinationWedding from '../components/DestinationWedding'
import Parking from '../components/Parking'
import Pool from '../components/Pool'
import PlayZone from '../components/PlayZone'
import Lawn from '../components/Lawn'


function Page() {
  return (
      <BrowserRouter>
        <Navbar/> 
        <Routes>
          <Route exact path="/" element={<Body/>}/>
          <Route exact path="/about" element={<AboutPage/>}/>
          <Route exact path="/rooms" element={<RoomPage/>}/>
          <Route exact path="/restaurant" element={<Restaurant/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/banquet" element={<Banquet/>}/>
          <Route exact path="/parking" element={<Parking/>}/>
          <Route exact path="/pool" element={<Pool/>}/>
          <Route exact path="/playZone" element={<PlayZone/>}/>
          <Route exact path="/lawn" element={<Lawn/>}/>
          <Route exact path="/destination" element={<DestinationWedding/>}/>

          <Route path="/*" element={<PageNotFound/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default Page
