import React from 'react'
import Card from './Card'
import Footer from './Footer'
import UpperNav from './UpperNav'
import parking from "../assets/parking.jpeg"


function Parking() {
  return (
    <div>
      <div>
        <Card 
            img={parking} 
            name="PARKING" 
            desc1="At Hotel Girish, we understand that convenience is paramount for our guests. That's why we're thrilled to offer a comprehensive parking facility designed to make your stay as hassle-free as possible." 
            desc2="Our hotel boasts a spacious and secure parking area, ensuring that your vehicle is safely accommodated throughout your stay. Rest assured, our vigilant security team keeps a watchful eye on the premises 24/7, offering you peace of mind during your time with us. Experience the convenience of hassle-free parking at Hotel Girish. Book your stay with us today and discover a world of comfort, convenience, and exceptional hospitality. Your journey begins here."/>
      </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default Parking
