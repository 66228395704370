import React from 'react'
import Card from './Card'
import dw from "../assets/dw.jpeg"
import UpperNav from './UpperNav'
import Footer from './Footer'


function DestinationWedding() {
  return (
    <div>
      <div>
        <Card img={dw} name="DESTINATION WEDDING" desc1="At Hotel Girish, we believe that every wedding should be a fairy tale come true, and what better way to celebrate your special day than in the lap of luxury amidst breathtaking surroundings? Nestled in Diamond Harbour, our hotel offers a picturesque setting for your dream destination wedding." desc2="Whether you're envisioning a lavish affair or an intimate gathering, Hotel Girish is the perfect venue for your destination wedding. Contact us today to start planning the wedding of your dreams and let us turn your vision into reality. Together, we'll create memories that last a lifetime."/>
      </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default DestinationWedding
