import React from "react";
import Rooms from "./Rooms";

import room1 from "../assets/room1.jpeg";
import room2 from "../assets/room2.jpeg";
import room3 from "../assets/room3.jpg";
import UpperNav from "./UpperNav";
import Footer from "../components/Footer";
import CarouselDefault from "./CarouselDefault";

function RoomPage() {
  const data= [
    {
        img:"r1.jpg"
    },
    {
        img:"r2.jpg"
    },
    {
        img:"r3.jpg"
    },
    {
        img:"r4.jpg"
    },
    {
        img:"r5.jpg"
    },
    {
        img:"r6.jpeg"
    },
    {
        img:"r7.jpeg"
    },
    {
        img:"r8.jpeg"
    },
    {
        img:"r9.jpeg"
    },
    {
        img:"r10.jpeg"
    },
    {
        img:"r11.jpeg"
    },
    {
        img:"r12.jpeg"
    }
];
  return (
    <div>
      <div className="underline font-bold text-3xl text-blue-900 text-center my-10">
        Rooms
      </div>
      <div className="sm:grid grid-cols-3 sm:space-x-3 space-y-2 sm:space-y-0 mx-5 my-5">
        <Rooms
          img={room1}
          seeMore={"hidden"}
          name={"Deluxe Room"}
          desc={
            "Thoughtfully designed interiors create a welcoming ambiance, ensuring a stay that feels both expansive and intimate. Choose between one king bed or two twin beds to best suit your needs. Perfect for meditation, these windowless rooms offer you complete tranquillity."
          }
        />
        <Rooms
          img={room2}
          seeMore={"hidden"}
          name={"Executive Room"}
          desc={
            "Indulge in the epitome of comfort and sophistication with our Suite Rooms, where every detail is meticulously crafted to provide you with an unforgettable stay. Check into your suite and enjoy a special treat as part of our premium turndown service includes chocolates/ pralines that you can enjoy with a drink from the complimentary minibar. Use the multimedia hub to connect your laptop to the flat screen via Bluetooth so you can stream your favorite shows or music before bedtime. Pamper yourself in our indulgent bathrooms featuring premium fixtures, and designer toiletries."
          }
        />
        <Rooms
          img={room3}
          seeMore={"hidden"}
          name={"Suite Room"}
          desc={
            "Welcome to our Suite Room! We're thrilled to welcome you to a world of luxury and comfort. Indulge in the lavish amenities and elegant design of our premium suite accommodations. Our dedicated team is here to ensure your every need is met, providing attentive service throughout your stay. Relax and unwind in the spacious and stylish surroundings of our suite room. Experience the epitome of sophistication and relaxation during your time with us. Your stay in our Suite Room is guaranteed to be a memorable and delightful experience."
          }
        />
      </div>
      <div className="mx-5 my-5">
        <hr />
        <div className="my-5 text-xl text-center text-blue-500">
          Drift into a peaceful slumber on our premium king-size beds adorned
          with the finest linens and plump pillows.
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0 justify-around">
          <div className="bg-blue-200 rounded-lg px-10 py-2 text-center font-semibold hover:text-xl">
            VIP food and beverages
          </div>
          <div className="bg-blue-200 rounded-lg px-10 py-2 text-center font-semibold hover:text-xl">
            Turndown service
          </div>
          <div className="bg-blue-200 rounded-lg px-10 py-2 text-center font-semibold hover:text-xl">
            Pick up and drop services
          </div>
        </div>
      </div>
      <div className='mx-5 my-20 text-center'>
            <div className='text-3xl text-blue-900 font-semibold'>Gallery</div>
            <div className='my-10 sm:px-20 sm:pb-20'>
                <CarouselDefault data={data}/>
            </div>
        </div>
      <div className="mx-5 my-10">
        <Footer />
      </div>
      <div className="sm:hidden sticky bottom-0 bg-white">
        <div className="mt-5">
          <h3 className="uppercase font-bold text-center">
            Hotel Girish
          </h3>
        </div>
        <UpperNav />
      </div>
    </div>
  );
}

export default RoomPage;