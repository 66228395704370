import React from "react";
import UpperNav from "./UpperNav";
import Footer from "../components/Footer";
import { FaEnvelope, FaMap, FaPhone } from "react-icons/fa";

function Contact() {
  return (
    <div>
      <div className="underline font-bold text-3xl text-blue-900 text-center my-5">
        Contact Us
      </div>
      <div className="sm:flex justify-around">
        <div>
          <div className="mx-5 text-lg space-y-2">
            <div>
              <a
                href="https://maps.app.goo.gl/ww9Btqtx7yaXw1pq7"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaMap className="text-blue-900" />
                </div>
                <div className="mt-6 sm:mt-0">
                  New Madhabpur (Maya Road), Diamond Harbour, South 24 Parganas, Pin - 743331, West Bengal
                </div>
              </a>
            </div>
            <div>
              <a
                href="mailto:girishhotel2014@gmail.com"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaEnvelope className="text-blue-900" />
                </div>
                <div>girishhotel2014@gmail.com</div>
              </a>
            </div>
            <div className="sm:flex space-x-3">
            <a
                href="tel:919593355995"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaPhone className="rotate-90 text-blue-900" />
                </div>
                <div>(+91) 9593355995</div>
              </a>
              <div className="hidden sm:block">|</div>
              <a
                href="tel:917810974176"
                className="flex items-center"
              >
                
                <div className="pl-4 sm:pl-0">(+91) 7810974176</div>
              </a>
            </div>
          </div>
        </div>
        <div className="mx-7 sm:mx-10 my-5 sm:my-0">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d118229.08589269599!2d88.11669816879936!3d22.177051187959957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3a025f046ae448a1%3A0xfa1096877e5b7967!2sMaya%20Rd%2C%20Diamond%20Harbour%2C%20Dhanberia%2C%20Diamond%20Harbour%2C%20West%20Bengal%20743331!3m2!1d22.177071899999998!2d88.1990998!5e0!3m2!1sen!2sin!4v1710922042092!5m2!1sen!2sin" 
            className="rounded-lg w-[85vw] sm:w-[50vw] sm:h-[50vh] border-0"
            title="map"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="mx-5 my-10">
        <Footer />
      </div>
      <div className="sm:hidden sticky bottom-0 bg-white">
        <div className="mt-5">
          <h3 className="uppercase font-bold text-center">
            Hotel Girish
          </h3>
        </div>
        <UpperNav />
      </div>
    </div>
  );
}

export default Contact;