import React from "react";
import UpperNav from "./UpperNav";
import CarouselDefault from "./CarouselDefault";
import res from "../assets/res1.jpg";
import Footer from "../components/Footer";

function Restaurant() {
  const data = [
    {
      img: "res2.jpeg",
    },
    {
      img: "res3.jpg",
    },
    {
      img: "res4.jpg",
    },
    {
      img: "res5.jpg",
    },
    {
      img: "res6.jpg",
    },
    {
      img: "res7.jpg",
    },
    {
      img: "res8.jpg",
    },
    {
      img: "res9.jpeg",
    },
    {
      img: "res10.jpeg",
    },
  ];
  return (
    <div>
      <div className="underline font-bold text-3xl text-blue-900 text-center my-5">
        Restaurant
      </div>
      <div className="mx-5 my-10">
        <div className="my-10 sm:px-20 sm:pb-20">
          <CarouselDefault data={data} />
        </div>
      </div>
      <div className="mx-5 my-10 text-center">
        <div className="text-2xl font-semibold">
          Welcome to Restaurant of Hotel Girish
        </div>
        <div className="text-blue-900 text-lg">
          Embark on a gastronomic journey that transcends borders and tantalizes
          your taste buds with our diverse and delectable menu. At our restaurant, we
          bring you an exquisite blend of cuisines, creating a symphony of
          flavors that will leave you craving for more.
        </div>
      </div>
      
      <div className="mx-5 my-10 text-center space-y-4">
        <div className="text-3xl font-bold text-blue-900">Special offers:</div>
        <hr />
        <div className="sm:flex text-center space-y-4 sm:space-y-0">
          <div>
            <div className="font-bold">🔥 Happy Hour Delights:</div>
            <div>
              Enjoy irresistible discounts on selected drinks and appetizers
              during our daily happy hour.
            </div>
          </div>
          <div>
            <div className="font-bold">🎂 Birthday Bonanza:</div>
            <div>
              Celebrate your special day with us and receive a complimentary
              dessert and a personalized birthday song from our staff.
            </div>
          </div>
          <div>
            <div className="font-bold">🎁 Gift Cards:</div>
            <div>
              Give the gift of a culinary adventure with our gift cards - the
              perfect present for any food enthusiast.
            </div>
          </div>
        </div>
        <hr />
        <div>
          Join us, where every meal is a celebration of taste,
          culture, and culinary artistry. Reservations are recommended, so book
          your table now and treat yourself to an unforgettable dining
          experience!
        </div>
      </div>
      <div className="mx-5 my-10 sm:flex sm:space-x-5">
        <div>
          <img src={res} alt="" className="rounded-lg w-[2000px]" />
        </div>
        <div className="my-3">
          <div className="text-3xl font-bold text-blue-900 text-center">
            Catering
          </div>
          <div className="my-3 mx-5">
            At Hotel Girish, we understand that every event is unique and
            deserves a touch of sophistication and excellence. Our catering
            services go beyond expectations, ensuring your guests savor not only
            the delicious cuisine but also the memorable experience we create.
          </div>
          <div className="my-3 mx-5">
            Our chefs work closely with the banquets and events team to provide
            a fantastic range of catering options, from breakfasts and light
            buffets to sumptuous four-course banquets. The hotel provides
            flexible catering solutions based on individual requests and tailors
            unique menus, whatever your requirements.
          </div>
        </div>
      </div>
      <div>
        <div className="text-3xl font-bold text-blue-900 text-center">
          What Sets Us Apart
        </div>
        <div className="mx-5 my-4">
          <div className="sm:grid grid-cols-3 sm:space-x-10 space-y-3 sm:space-y-0 text-center my-3">
            <div className="bg-blue-200 px-3 py-2 rounded-lg">
              Culinary Mastery-From exquisite appetizers to delectable main
              courses and indulgent desserts, our culinary creations caters to
              diverse palates and are a feast for the senses.
            </div>
            <div className="bg-blue-200 px-3 py-2 rounded-lg">
              Global Flavors, Local Excellence- Our catering services promise an
              unforgettable gastronomic adventure with the fusion of globally
              famous locally improvised dishes.
            </div>
            <div className="bg-blue-200 px-3 py-2 rounded-lg">
              Customized Menus- that fit your choice and your preferences,
              dietary requirements, and theme, ensuring a bespoke dining
              experience that reflects your vision.
            </div>
          </div>
          <div className="sm:grid grid-cols-2 sm:space-x-10 space-y-3 sm:space-y-0 text-center my-3">
            <div className="bg-blue-200 px-3 py-2 rounded-lg">
              Seamless Service-From setup to cleanup, our professional catering
              staff ensures a seamless and stress-free experience. We handle
              every detail, allowing you to focus on enjoying the event while we
              take care of the rest.
            </div>
            <div className="bg-blue-200 px-3 py-2 rounded-lg">
              Corporate Excellence: Impress clients, colleagues, and partners
              with our sophisticated catering services for business meetings,
              conferences, and corporate events. Elevate your professional
              gatherings with the culinary excellence that defines Hotel Eden
              Garden.
            </div>
          </div>
        </div>
        <hr />
        <div className="mx-5 text-center text-lg">
          For an unparalleled catering experience that exceeds expectations,
          choose Hotel Girish. Contact us today to start planning
          your event, and let us turn your vision into a culinary masterpiece!
        </div>
      </div>
      <div className="mx-5 my-10">
        <Footer />
      </div>
      <div className="sm:hidden sticky bottom-0 bg-white">
        <div className="mt-5">
          <h3 className="uppercase font-bold text-center">
            Hotel Girish
          </h3>
        </div>
        <UpperNav />
      </div>
    </div>
  );
}

export default Restaurant;