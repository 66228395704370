import React from 'react'
import Card from './Card'
import conference from "../assets/conference.jpg"
import poolParty from "../assets/poolParty.jpeg"
import corporateEvent from "../assets/corporateEvent.jpeg"
import event from "../assets/event.jpeg"
import Footer from './Footer'
import UpperNav from './UpperNav'
import CarouselDefault from './CarouselDefault'


function Banquet() {
  const data= [
    {
        img:"bt1.jpeg"
    },
    {
        img:"bt2.jpeg"
    },
    {
        img:"bt3.jpeg"
    },
    {
        img:"bt4.jpeg"
    },
    {
        img:"bt5.jpeg"
    },
    {
        img:"bt6.jpg"
    },
    {
        img:"bt7.jpg"
    },
    {
        img:"bt8.jpeg"
    }
];
  return (
    <div>
      <div className='underline font-bold text-3xl text-blue-800 text-center my-5'>
            Banquet
      </div>
      <div className='mx-5 my-5 flex justify-center'>
        <div className='bg-blue-200 px-3 py-2 font-semibold rounded-lg hover:text-xl'>Leela</div>
        <div>________________________</div>
        <div className='bg-blue-200 px-3 py-2 font-semibold rounded-lg hover:text-xl'>Gyatri</div>
      </div>
      <div>
        <Card img={conference} name="CONFERENCE" desc1="Thinktank at Hotel Girish is strategically located,
                making it easily accessible for attendees. Ample parking facilities and proximity to
                public transportation add to the convenience of hosting your event with us." desc2=" We are committed to sustainability. Our conference hall is
                designed with eco-friendly practices, ensuring that your event leaves a positive
                impact on the environment."/>
      </div>
      <div>
        <Card 
          img={corporateEvent} 
          name="CORPORATE PROGRAMME" 
          desc1="Booking your corporate event at Hotel Girish is a seamless experience. Our dedicated event planning team is here to assist you every step of the way, ensuring that every aspect of your event is executed flawlessly. From initial consultation to event execution, we are committed to delivering unparalleled service and attention to detail." 
          desc2="Elevate your next corporate event to new heights at Hotel Girish. Contact us today to inquire about availability and start planning an unforgettable experience for you and your guests. We look forward to hosting you at Hotel Girish, where your success is our priority."/>
      </div>
      <div>
        <Card img={poolParty} name="POOL PARTY" 
                desc1="Are you looking for the perfect venue to host a sensational pool party that will be talked about for years to come? Look no further than Hotel Girish! Nestled in the heart of Diamond Harbour, our luxurious hotel boasts a stunning banquet hall coupled with a sparkling poolside setting, creating the ideal backdrop for your next unforgettable event." 
                desc2="Contact us today to book our banquet hall for your upcoming pool party at Hotel Girish. Our experienced team will work closely with you to ensure that every aspect of your event is taken care of, leaving you free to relax and enjoy the festivities.                Don't miss out on the opportunity to create memories that will last a lifetime. Reserve your spot at Hotel Girish and let us turn your pool party dreams into a reality!"/>
      </div>
      <div>
        <Card img={event} name="EVENTS" desc1="At Hotel Girish, we understand the significance of life's special moments and strive to make them unforgettable. Our exquisite banquet hall is the perfect venue for a range of occasions, including weddings, birthdays, and anniversaries. Whether you're planning an intimate gathering or a grand celebration, we have the facilities and expertise to ensure your event is a resounding success." 
        desc2="Booking your event at Hotel Girish is easy. Simply fill out the form below to inquire about availability and discuss your requirements with our event planning team. Whether you're planning a lavish wedding, a joyous birthday celebration, or a romantic anniversary dinner, we look forward to helping you create memories that will last a lifetime."/>
      </div>
      <div className='mx-5 my-20 text-center'>  
            <div className='text-3xl text-blue-900 font-semibold'>Gallery</div>
            <div className='my-10 sm:px-20 sm:pb-20'>
                <CarouselDefault data={data}/>
            </div>
        </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default Banquet
