import React from 'react'
import play from "../assets/play.jpg"
import play1 from "../assets/play1.jpg"
import play2 from "../assets/play2.jpg"
import play3 from "../assets/play3.jpeg"

import Card from './Card'
import Footer from './Footer'
import UpperNav from './UpperNav'


function PlayZone() {
  return (
    <div>
      <div>
        <Card img={play} name="KID'S PLAY ZONE" desc1="At Hotel Girish, we understand that providing an unforgettable experience goes beyond comfortable accommodations and delectable dining. That's why we are thrilled to introduce our latest addition to our array of services - the Play Zone Facility!" desc2="Ready to experience the thrill of our Play Zone Facility at Hotel Girish? Book your stay now and elevate your vacation to new heights of excitement and enjoyment. Don't miss out on the fun - reserve your spot today!"/>
      </div>
      <div className='sm:grid grid-cols-3 mx-10 my-10 sm:space-x-5 space-y-3 sm:space-y-0'>
        <div><img src={play1} alt="" className='rounded-lg'/></div>
        <div><img src={play2} alt="" className='rounded-lg'/></div>
        <div><img src={play3} alt="" className='rounded-lg'/></div>
      </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Girish</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default PlayZone
