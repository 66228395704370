import React from "react";
import {
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";

const UpperNav = () => {
  return (
    <>
      <div className="sm:flex sm:justify-between sm:items-center my-5 mx-5">
        <div className="sm:flex sm:gap-x-5">
          <a href="tel:+919593355995">
            <div className="flex items-center justify-center gap-x-1 my-2 sm:my-0">
              <div>
                <FaPhone className="rotate-90 text-blue-500" />
              </div>
              <p className="text-sm">(+91) 9593355995</p>
            </div>
          </a>
          <div className="hidden sm:block font-extrabold text-blue-600">|</div>
          <a href="tel:+917810974176">
            <div className="flex items-center justify-center gap-x-1 my-2 sm:my-0">
              <div>
                <FaPhone className="rotate-90 text-blue-500" />
              </div>
              <p className="text-sm">(+91) 7810974176</p>
            </div>
          </a>
          <div className="hidden sm:block font-extrabold text-blue-600">|</div>
          <a href="mailto:girishhotel2014@gmail.com">
            <div className="flex items-center justify-center gap-x-1 my-2 sm:my-0">
              <div>
                <FaEnvelope className="text-blue-500" />
              </div>
              <p className="text-sm">girishhotel2014@gmail.com</p>
            </div>
          </a>
        </div>
        <div className="sm:flex sm:gap-x-5 items-center justify-center my-2 sm:my-0">
          <div className="flex items-center justify-center gap-x-2 text-xl">
            <div>
              <FaFacebook className="text-blue-500" />
            </div>
            <div>
              <FaTwitter className="text-blue-500" />
            </div>
            <div>
              <FaInstagram className="text-blue-500" />
            </div>
            <div>
              <FaWhatsapp className="text-blue-500" />
            </div>
          </div>
          <a href="tel:+919593355995">
            <div className="mt-2 flex justify-center items-center">
              <button className="bg-blue-900 text-white px-3 py-2 w-full rounded-lg font-semibold">
                Book Now
              </button>
            </div>
          </a>
        </div>
      </div>
      <hr />
    </>
  );
};

export default UpperNav;